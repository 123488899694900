import React, { useState } from "react";
import { Container } from "@mui/material";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
import { LoadingContext } from "./contexts/LoadingContext";
import { LoadingBackDrop } from "./components";
import { router } from "./utils/router";
import { RouterProvider } from "react-router-dom";

function App() {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <ThemeProvider theme={theme}>
        <LoadingBackDrop />
        <Container maxWidth="md">
          <RouterProvider router={router} />
        </Container>
      </ThemeProvider>
    </LoadingContext.Provider>
  ); 
}

export default App;
