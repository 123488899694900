import { FormHelperText } from "@mui/material";
import { ReactNode } from "react";
import { SxProps } from "@mui/material";

export default function CustomFormHelperText({
  children,
  sx,
  error,
}: CustomFormHelperTextProps) {
  return (
    <FormHelperText
      error={error}
      sx={{ marginX: 0, fontSize: 12, fontWeight: 300, ...sx }}
    >
      {children}
    </FormHelperText>
  );
}

type CustomFormHelperTextProps = {
  children: ReactNode;
  sx?: SxProps;
  error?: boolean;
};
