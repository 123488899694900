import {
  InputBaseComponentProps,
  TextField,
  TextFieldProps,
} from "@mui/material";

export default function CustomTextField({
  inputProps,
  ...props
}: CustomTextFieldProps) {
  return (
    <TextField
      variant="outlined"
      size="small"
      inputProps={{
        style: {
          fontSize: 14,
        },
        ...inputProps,
      }}
      style={{
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 4px rgba(18, 18, 64, 0.08)",
        borderRadius: "8px",
        marginTop: "12px",
      }}
      sx={{
        "& fieldset": { border: "none" },
      }}
      {...props}
    />
  );
}

type CustomTextFieldProps = {
  inputProps?: InputBaseComponentProps;
} & TextFieldProps;
