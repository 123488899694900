import axios from "axios";
import { client } from "../utils/Api";

type PostSubmitSurveyRequest = {
  question: number;
  questionLabel: string;
  answers: string[];
};

const postSubmitSurvey = async (payload: PostSubmitSurveyRequest[]) => {
  try {
    const response = await client.post("/oapi/survey/extend-tenor", {
      command: "extend-tenor-survey",
      data: {
        questions: payload,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
    return axios.isAxiosError(error) && error.response ? error.response : null;
  }
};

export { postSubmitSurvey };
